import './App.css';

function App() {
  return (
    <div className="App">
      
<div class="loader" id="loader-fade">
    <div class="dot-container">
        <div class="dot dot-1"></div>
        <div class="dot dot-2"></div>
        <div class="dot dot-3"></div>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
            <filter id="goo">
                <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7"/>
            </filter>
        </defs>
    </svg>
</div>
<header>
  
    <nav class="navbar navbar-top-default navbar-expand-lg navbar-simple nav-line">
        <ul class="mb-0 top-info">
            <li class="c-links d-none d-lg-block"><a href="https://wa.me/5491135923634" target="blank"><img src="images/service-elbaron-contacto-whatsapp.png" width="40" alt="El baron service" title="El baron service" />&nbsp;&nbsp;+54 9 11 3592-3634</a></li>
        </ul>
        <div class="container">
            <a href="/" title="Logo" class="logo">EL BARÓN SERVICE</a>
            
            <div class="collapse navbar-collapse" id="megaone">
                <div class="navbar-nav ml-auto">
                    <a class="nav-link active scroll" href="#home-banner">INICIO</a>
                    <a class="nav-link scroll" href="#servicios">SERVICIOS</a>
                    <a class="nav-link scroll" href="#la-empresa">LA EMPRESA</a>
                    <a class="nav-link scroll" href="#contacto">CONTACTO</a>
                </div>
            </div>
            
            <p class="sidemenu_btn" id="sidemenu_toggle">
                <span></span>
                <span></span>
                <span></span>
            </p>
        </div>
        
        <div class="nav-social text-white d-flex justify-content-center">
            <ul class="social-icons-simple">
                <li><a class="instagram-text-hvr" href="https://www.instagram.com/elbaronservice/" target="_blank"><i class="fab fa-instagram"></i> </a> </li>
                <li><a class="facebook-text-hvr" href="https://www.facebook.com/elbaronservice" target="_blank"><i class="fab fa-facebook-f"></i> </a> </li>
            </ul>
        </div>
    </nav>
    
    <div class="side-menu hidden">
        <div class="inner-wrapper">
            <span class="btn-close" id="btn_sideNavClose"><i></i><i></i></span>
            <nav class="side-nav w-100">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#home-banner">INICIO</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#servicios">SERVICIOS</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#la-empresa">LA EMPRESA</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#contacto">CONTACTO</a>
                    </li>
                </ul>
            </nav>

            <div class="side-footer text-white w-100">
                <ul class="social-icons-simple">
                    <li><a class="instagram-text-hvr" href="https://www.instagram.com/elbaronservice/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    <li><a class="facebook-text-hvr" href="https://www.facebook.com/elbaronservice/" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                </ul>
                <p class="text-white">&copy; 2021 El Barón Service. Website desarrollado por <a href="https://fusionesweb.com" class="fusionesWeb">fusionesWeb</a></p>
            </div>
        </div>
    </div>
    <span id="close_side_menu"></span>
    
</header>

<section class="banner-area" id="home-banner">
    <div class="container">
        <div class="row center-content">
            <div class="col-12 col-md-10 col-lg-6 offset-md-1 offset-lg-6">
                <div class="green-box">
                    <div class="box-content">
                        <h1 class="wow fadeInUp" data-wow-delay="1.2s">El Barón Service</h1>
                        <h2 class="wow fadeInUp" data-wow-delay="1.6s">Reparaciones Integrales</h2><br />
                        <a href="#servicios" class="scroll button wow fadeInUp" data-wow-delay="2s"><i class="las la-angle-right"></i>Nuestros Servicios</a>
                        <br /><br />
                        <h4>* Heladeras * Lavarropas * Electricidad<br />* Destapaciones con máquinas</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-sec" id="servicios">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-5">
                <div class="heading-area">
                    <h4 class="heading wow slideInLeft">Personal <span> capacitado</span></h4>
                </div>
            </div>
            <div class="col-12 col-lg-7">
                <div class="image-holder wow slideInRight">
                    <img src="images/servicios-el-baron-personal-capacitado.jpg" alt="El baron service - Personal capacitado" title="El baron service - Personal capacitado" />
                </div>
            </div>
            <div class="col-12 padding-top-half">
                <ul class="services-boxes">
                    
                    <li class="service-card left wow slideInLeft">
                        <div class="icon-holder"><img src="images/heladera-reparar.jpg" width="700" height="990" title="El baron service - Reparacion de heladera" alt="El baron service - Reparacion de heladera" /></div>
                        <p class="text">Servicios de reparación e instalación de heladeras familiares, freezer de pozo, verticales, heladeras exhibidoras y cámaras de frio</p>
                    </li>
                    
                    <li class="service-card right d-block text-center wow slideInRight">
                        <div class="icon-holder"><img src="images/lavarropas-reparar.jpg" width="700" height="990"  title="El baron service - Reparacion de lavarropas automáticos, carga superior, carga frontal" alt="El baron service - Reparacion de lavarropas automáticos, carga superior, carga frontal" /></div>
                        <p class="text">Service de lavarropas automáticos, carga superior, carga frontal</p>
                    </li>
                    {/*
                    <li class="service-card left icon-right text-center text-lg-right wow slideInLeft">
                        <p class="text order-2 order-lg-1">Reparación, instalación y mantenimiento de Aire Acondicionado y Calefacción. Equipos split, multisplit, y centrales.</p>
                        <div class="icon-holder order-1 order-lg-2"><img src="images/aire-acondicionado-reparar.jpg" title="El baron service - Reparacion de aire acondicionado" alt="El baron service - Reparacion de aire acondicionado" width="900" height="990"  /></div>
                    </li>
                    */}
                    <li class="service-card left icon-right text-center text-lg-right wow slideInLeft">
                        <p class="text order-2 order-lg-1">Reparación de Hornos eléctricos</p>
                        <div class="icon-holder order-1 order-lg-2"><img src="images/horno-electrico-service.jpg" title="El baron service - Reparacion de Hornos eléctricos" alt="El baron service - Reparacion de Hornos eléctricos" width="900" height="990"  /></div>
                    </li>
                    
                    <li class="service-card left d-block text-center wow slideInRight">
                        <p class="text order-2 order-lg-1">Servicio de electricidad: colocación de luminarias, fotocélulas y sensores, conexiones, ventiladores de techo, automático de tanques de agua.</p>
                        <div class="icon-holder order-1 order-lg-2"><img src="images/servicio-electricidad-casa-domestico.jpg" title="El baron service - Servicio de electricidad doméstico" alt="El baron service - Servicio de electricidad doméstico" width="900" height="990"  /></div>
                    </li>

                    <li class="service-card right d-block text-center wow slideInLeft">
                        <p class="text order-2 order-lg-1">Destapaciones con máquina. Cañerías, cloacas, pluvial, baños, cocinas.</p>
                        <div class="icon-holder order-1 order-lg-2"><img src="images/destapaciones-con-maquina.jpg" title="El baron service - Destapaciones con máquina. Cañerías, cloacas, pluvial, baños, cocinas" alt="El baron service - Destapaciones con máquina. Cañerías, cloacas, pluvial, baños, cocinas" width="900" height="990"  /></div>
                    </li>

{/*} 
                    <li class="service-card right d-block text-center wow slideInLeft">
                        <p class="text order-2 order-lg-1">Trabajos de albañilería general. Refacciones, pared, piso, carpetas, cerámicas.</p>
                        <div class="icon-holder order-1 order-lg-2"><img src="images/servicio-albañileria.jpg" title="El baron service - Reparacion de aire acondicionado" alt="El baron service - Reparacion de aire acondicionado" width="900" height="990"  /></div>
                    </li>
                    
                    <li class="service-card left icon-right text-center text-lg-right wow slideInLeft">
                        <p class="text order-2 order-lg-1">Trabajos de pintura general. Interior, exterior, frentes. Pintura látex, sintéticos, laca, satinados, barniz.</p>
                        <div class="icon-holder order-1 order-lg-2"><img src="images/servicio-pintureria.jpg" title="El baron service - Reparacion de aire acondicionado" alt="El baron service - Reparacion de aire acondicionado" width="900" height="990"  /></div>
  </li>*/}
                    

                </ul>
            </div>
        </div>
    </div>
</section>

<section class="portfolio" id="la-empresa">
    <div id="project-sec" class="project-sec">
        <div class="projects owl-carousel owl-theme no-gutters">
          
            <div class="item project-area">
                <div class="project-img order-1">
                    <img src="images/elbaron-servicio-tecnico-confianza.jpg" title="El baron service - Servicio técnico de confianza" alt="El baron service - Servicio técnico de confianza" />
                </div>
                <div class="container">
                    <div class="project-detail text-center text-lg-left order-2">
                        <div class="row no-gutters">
                            <div class="col-12 offset-md-12 col-lg-6 animate-fade">
                                <div class="black-box">
                                <h6 class="project-heading">El Barón servicio integral</h6>
                                <p class="project-text">Somos una agencia de servicios integrales para el hogar. Trabajamos con técnicos con más de 20 años en el rubro y siempre con garantía de responsabilidad y honestidad. </p>
                                <a href="#contacto" class="nav-link scroll btn btn-medium btn-rounded btn-white">Contacto</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="item project-area">
                <div class="project-img order-1">
                    <img src="images/elbaron-marcas-trabajadas.jpg" title="El baron service - Marcas que trabajamos" alt="El baron service - Marcas que trabajamos" />
                </div>
                <div class="container">
                    <div class="project-detail text-center text-lg-left order-2">
                        <div class="row no-gutters">
                            <div class="col-12 offset-md-12 col-lg-6 animate-fade">
                                <div class="black-box">
                                    <h6 class="project-heading">Algunas marcas trabajadas</h6>
                                    <p class="project-text">Patrick, Gafa, Mabe, GE, Whirlpool, Samsung, Sanyo, Philco, Siam, Columbia, Eslabón de lujo, Koh-i-noor, Carrier, Aurora, Surrey, Electrolux, Sigma, Longvie, LG.</p>
                                <a href="#contacto" class="nav-link scroll btn btn-medium btn-rounded btn-white">Contacto</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        
        <div class="project-links-left d-none d-md-block">
            <button class="customPrevBtn"><i class="las la-angle-left"></i></button>
        </div>
        <div class="project-links-right d-none d-md-block">
            <button class="customNextBtn"><i class="las la-angle-right"></i></button>
        </div>

    </div>
</section>

<section class="contact-sec" id="contacto">
    <div class="container expand-container">
        <div class="row align-items-center">
            <div class="col-12 col-md-12 col-lg-5 wow slideInLeft" data-wow-delay=".5s">
                <div class="heading-area">
                    <h4 class="heading">Preguntas? <span class="d-block"> Contactanos</span></h4>
                </div>
                <div class="contact-details">
                    <ul>
                        <li><i aria-hidden="true" class="las la-paper-plane"></i><a href="mailto:contacto@elbaronservice.com.ar">contacto@elbaronservice.com.ar</a></li>
                        <li><i aria-hidden="true" class="las la-phone"></i><span><a href="https://wa.me/5491135923634" target="blank">+54 9 11 3592-3634</a></span></li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-md-12 col-lg-7 wow slideInRight" data-wow-delay=".5s">
                <form class="row contact-form row-padding" id="contactusForm" action="process.php" method="post">
                    <div class="col-12 col-lg-10" id="result"></div>
                    <div class="col-12 col-lg-10">
                        <div class="form-group">
                            <input type="text" name="name" placeholder="Nombre" class="form-control" />
                        </div>
                        <input type="text" name="phone" placeholder="Teléfono de contacto" class="form-control" />
                        <input type="email" name="email" placeholder="Email" class="form-control" />
                        <textarea class="form-control" name="message" rows="6" placeholder="Ingresa tu mensaje aquí..."></textarea>
                        <button type="submit" class="btn btn-medium btn-rounded btn-trans rounded-pill w-100 contact_btn main-font">Enviar consulta</button>
                    </div>
                </form>

            </div>
        </div>
    </div>
</section>

<section id="foot">
    <div class="container expand-container">
                <p class="text-white">&copy; 2021 El Barón Service. Website desarrollado por <a href="https://fusionesweb.com" class="fusionesWeb">fusionesWeb</a></p>
      </div>
</section>


<div class="go-top"><i class="fas fa-angle-up"></i><i class="fas fa-angle-up"></i></div>

    </div>
  );
}

export default App;
